import * as React from 'react';
import PageTemplate from '../components/page-template';

const Contact = () => {
  return (
    <PageTemplate className="my-5">
      <h1>Contact Me</h1>
      <p>This page is currently under development. Please come back later.</p>
    </PageTemplate>
  )
};

export default Contact;
